/* eslint-disable eol-last */
const contentRouter = {
  route: null,
  name: null,
  title: '内容管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/content/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: '漫画列表',
      type: 'view',
      name: 'ComicList',
      route: '/comic/list',
      filePath: 'view/content/comic-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      isElementIcon: false,
    },
    {
      title: '添加漫画',
      type: 'view',
      name: 'ComicCreate',
      route: '/comic/add',
      filePath: 'view/content/comic.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
    },
    {
      title: '章节列表',
      type: 'view',
      name: 'ChapterList',
      route: '/chapter/list',
      filePath: 'view/content/chapter-list.vue',
      inNav: false,
      icon: 'iconfont icon-tushuguanli',
      isElementIcon: false,
    },
    {
      title: '添加章节',
      type: 'view',
      name: 'ChapterCreate',
      route: '/chapter/add',
      filePath: 'view/content/chapter.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
    },
  ],
}

export default contentRouter
