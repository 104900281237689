/* eslint-disable eol-last */
const operationRouter = {
  route: null,
  name: null,
  title: '运营管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  isElementIcon: false,
  filePath: 'view/operation/', // 文件路径
  order: null,
  inNav: true,
  children: [
    {
      title: 'APP运营',
      type: 'view',
      name: 'ComicCreate',
      route: '/operation/app-list',
      filePath: 'view/operation/app-list.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
    },
    {
      title: '首页推荐模块',
      type: 'view',
      name: 'RecommendList',
      route: '/operation/recommend-list',
      filePath: 'view/operation/recommend-list.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      isElementIcon: false,
    },
  ],
}

export default operationRouter
